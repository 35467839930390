import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Text from '@zenbusiness/common-components/Text';
import Title from '@zenbusiness/common-components/Title';
import Button from '@mui/material/Button';
import { useEnv } from '@zenbusiness/application-commons-env';
import { useIdentity } from '@zenbusiness/application-commons-identity';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import SharedAsset from '@zenbusiness/common-components/SharedAsset';
import superstyled from '@zenbusiness/common-components/superstyled';
import NavItem from './NavItem';
// import UUIDSearch from './UUIDSearch';

const ZBLogoIcon = superstyled({
  tag: SharedAsset,
  name: 'ZBLogoIcon',
  propsAdded: {
    src: '/logo/circle-logo-teal.svg',
    width: 30
  },
  styles: ({ theme }) => ({
    marginRight: theme.spacing(1)
  })
});

export const hasPermission = (
  permissions,
  ...requiredPermissions
) => requiredPermissions.some((permission) => permissions.has(permission));

export const hasRecommendationToolPermission = (identified, permissions, deployEnv) => identified && deployEnv !== 'production' && (
  hasPermission(permissions, 'oar:admin', 'oar:product', 'oar:dev')
);

export const hasFintechToolPermission = (identified, permissions) => identified && hasPermission(
  permissions,
  'faui:eng',
  'faui:admin',
  'faui:cs',
  'access:developers',
  'access:internal'
);

export default function TopNav() {
  const {
    authenticated, identified, name, permissions
  } = useIdentity();

  const { DEPLOY_ENV } = useEnv();

  const hasEcatAdminPermission = permissions?.has('ecat:admin');
  const isRecommendationToolVisible = hasRecommendationToolPermission(
    identified,
    permissions,
    DEPLOY_ENV
  );
  const isFintechToolVisible = hasFintechToolPermission(identified, permissions);
  const isToolsVisible = isRecommendationToolVisible || isFintechToolVisible;

  const menu = (
    <>
      <NavItem key="accounts" label="Accounts" href="/account" />
      <NavItem key="orders" label="Orders" href="/order" />
      <NavItem key="business-entities" label="Business Entities" href="/business-entity" />
      <NavItem key="developers" label="Developers">
        <NavItem label="ZenAPI Specification" href="/catalog/default/api/zenapi/definition" />
        <NavItem label="Data Alerts" href="/developers/data-alerts" />
        <NavItem label="ZenGarden Components" href="/developers/components" />
        <NavItem label="Order creation error handling" href="/developers/retry-order-creation" />
      </NavItem>
      <NavItem key="products" label="Products & Pricing">
        {hasEcatAdminPermission && (
        <NavItem label="Price List Manager" href="/products/price-list-manager" />) }
        <NavItem label="Manage Affiliates" href="/products/create-new-affiliate" />
        <NavItem label="Affiliate Pricing" href="/products/affiliate-pricing" />
        <NavItem label="Coupons" href="/products/coupons" />
        <NavItem label="Affiliate Plans" href="/products/affiliate-plan-options" />
        <NavItem label="Plans" href="/products/plan-options" />
        <NavItem label="Services C-Corp" href="/products/services-c-corp" />
        <NavItem label="Services Fulfillment" href="/products/services-fulfillment" />
        <NavItem label="Service Fees" href="/products/service-fees" />
        <NavItem label="Service Pricing" href="/products/service-pricing" />
        <NavItem label="Service Products" href="/products/service-products" />
        <NavItem label="Service Product Plans" href="/products/service-product-plans" />
        <NavItem label="Amendments & Annual Reports Configuration" href="/products/amendment-report-field-config" />
      </NavItem>
      <NavItem key="automation" label="Automation">
        <NavItem label="Automation Status" href="/automation/automation-status" />
        <NavItem label="Automated Filings" href="/automation/automation-filings" />
        <NavItem label="Generate Payload" href="/automation/generate-payload" />
        <Divider />
        <NavItem label="CA Login Access Queue" href="/automation/ca-login-access-queue" />
      </NavItem>
      <NavItem key="zebra" label="ZeBRA" href="/zebra" />
      {isToolsVisible && (
        <NavItem key="tools" label="Tools">
          {isRecommendationToolVisible && (
            <NavItem label="Recommendation Admin Tool" href="/re/admin" />
          )}
          {isFintechToolVisible && (
            <NavItem label="Fintech Admin Tool" href="/fintech/admin" />
          )}
        </NavItem>
      )}
      {/* <UUIDSearch /> */}
    </>
  );

  return (
    <AppBar position="static">
      <Toolbar disableGutters sx={{ px: 2, py: 1 }}>
        <ZBLogoIcon />
        <Title variant="smallBold" color="focus10">ZenGarden</Title>
        <Box flexGrow={1} display="flex" mx={3}>{identified && menu}</Box>
        {identified && <Text sx={{ mx: 2 }}>{name}</Text>}
        {authenticated && <Button component={Link} to="/logout">Logout</Button>}
        {!authenticated && <Button component={Link} to="/login">Login</Button>}
      </Toolbar>
    </AppBar>
  );
}
