import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useEnv } from '@zenbusiness/application-commons-env';
import Empty from '../Empty';

const DevBannerRoot = styled(Box)({
  color: 'white',
  background: 'repeating-linear-gradient(45deg,#329498,#329498 20px,#205d5f 0,#205d5f 40px)',
  textShadow: '0.0625rem 0.0625rem 0.0625rem #2e2e2e',
  textAlign: 'center',
  fontSize: '1rem'
});

export default function DevBanner({ children, ...props }) {
  const { DEPLOY_ENV } = useEnv();
  if (DEPLOY_ENV === 'production') return <Empty production />;
  return (
    <DevBannerRoot {...props}>{children || String(DEPLOY_ENV).toUpperCase()}</DevBannerRoot>
  );
}
