/* eslint-disable prefer-destructuring */

/**
 * @typedef BuildEnv
 * Environment configuration available at the time that webpack built the frontend bundle.
 *
 * @property {string} NAME Name of the application as defined in package.json
 * @property {string} RELEASE_VERSION Sha of the commit released ("unset" in local)
 * @property {string} DEFAULT_SEGMENT The opening path prefix of the application (eg, "/x")
 * @property {'test'|'development'|'production'} NODE_ENV The NODE_ENV environment variable.
 * This governs application behavior and DOES NOT indicate what server environment the
 * application is running in. See DEPLOY_ENV for the server environment.
 * @property {boolean} IS_PROD Indicates if NODE_ENV is "production"
 * @property {boolean} IS_DEV Indicates if NODE_ENV is "development"
 * @property {boolean} IS_LOCAL Indicates if the application is running on a local machine
 * @property {boolean} IS_WEBPACK Indicates if the application is running in webpack-dev-server
 * @property {'webpack'|'local'|'test'|'development'|'production'} DEPLOY_ENV The DEPLOY_ENV
 * environment variable. This identifies what server environment the application was built in.
 * @property {{[name:string]: string}} ROUTES Dictionary of all routes defined in package.json
 * @property {string} DEFAULT_ROUTE The default landing page route for this application.
 * @property {string} GRAPHQL_ROUTE The uri to the graphql endpoint for this application.
 * @property {{ loginUri: string, logoutUri: string, callbackUri: string }} AUTH_PATHS
 */

/** @type {BuildEnv} */
const BUILD_ENV = process.env.BUILD_ENV;

export const {
  NAME,
  RELEASE_VERSION,
  DEFAULT_SEGMENT,
  ROUTES,
  DEFAULT_ROUTE,
  GRAPHQL_ROUTE,
  AUTH_PATHS
} = BUILD_ENV;

export default BUILD_ENV;
