import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import ErrorPage from '@zenbusiness/dashboard-components/ErrorPage';
import { DEFAULT_SEGMENT } from '#client/build-env';

// import Authenticated from '#client/components/Authenticated';
// import Loading from '#client/components/Loading';

import Template from '#client/components/Template';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Callback from './pages/Callback';
import Landing from './pages/Landing';

const SessionDebug = lazy(() => import(/* webpackChunkName: 'landing' */'./pages/SessionDebug'));

export function DirectRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/callback" element={<Callback />} />
      <Route path="/logout" element={<Logout />} />

      <Route element={<Template />}>
        <Route index element={<Landing />} />

        <Route path={DEFAULT_SEGMENT}>
          <Route index element={<Landing />} />
          <Route path="debug" element={<SessionDebug />} />
        </Route>
      </Route>

      <Route path="*" element={<Template><ErrorPage><p>The requested page could not be found.</p></ErrorPage></Template>} />
    </Routes>
  );
}
