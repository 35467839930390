// Generated by Avo VERSION 121.0.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* eslint-disable */

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers) {
    this.map = {};

    if (headers instanceof Headers) {
      headers.forEach(function (value, name) {
        this.append(name, value);
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header) {
        this.append(header[0], header[1]);
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name) {
        this.append(name, headers[name]);
      }, this);
    }
  }

  Headers.prototype.append = function (name, value) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name, value) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items = [];
    this.forEach(function (value, name) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items = [];
    this.forEach(function (value) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items = [];
    this.forEach(function (value, name) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body) {
    if (body.bodyUsed) {
      return Promise.reject(new TypeError('Already read'))
    }
    body.bodyUsed = true;
  }

  function fileReaderReady(reader) {
    return new Promise(function (resolve, reject) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]);
    }
    return chars.join('')
  }

  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    this.bodyUsed = false;

    this._initBody = function (body) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      this.blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return rejected
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob)
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob')
        } else {
          return Promise.resolve(new Blob([this._bodyText]))
        }
      };

      this.arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          return consumed(this) || Promise.resolve(this._bodyArrayBuffer)
        } else {
          return this.blob().then(readBlobAsArrayBuffer)
        }
      };
    }

    this.text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return rejected
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob)
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text')
      } else {
        return Promise.resolve(this._bodyText)
      }
    };

    if (support.formData) {
      this.formData = function () {
        return this.text().then(decode)
      };
    }

    this.json = function () {
      return this.text().then(JSON.parse)
    };

    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input, options) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError('Already read')
      }
      this.url = input.url;
      this.credentials = input.credentials;
      if (!options.headers) {
        this.headers = new Headers(input.headers);
      }
      this.method = input.method;
      this.mode = input.mode;
      this.signal = input.signal;
      if (!body && input._bodyInit != null) {
        body = input._bodyInit;
        input.bodyUsed = true;
      }
    } else {
      this.url = String(input);
    }

    this.credentials = options.credentials || this.credentials || 'same-origin';
    if (options.headers || !this.headers) {
      this.headers = new Headers(options.headers);
    }
    this.method = normalizeMethod(options.method || this.method || 'GET');
    this.mode = options.mode || this.mode || null;
    this.signal = options.signal || this.signal;
    this.referrer = null;

    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders) {
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit, options) {
    if (!options) {
      options = {};
    }

    this.type = 'default';
    this.status = options.status === undefined ? 200 : options.status;
    this.ok = this.status >= 200 && this.status < 300;
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    this.headers = new Headers(options.headers);
    this.url = options.url || '';
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    return new Response(null, { status: status, headers: { location: url } })
  };

  self.DOMException = self.DOMException;
  try {
    new self.DOMException();
  } catch (err) {
    self.DOMException = function (message, name) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    self.DOMException.prototype = Object.create(Error.prototype);
    self.DOMException.prototype.constructor = self.DOMException;
  }

  function fetch(input, init) {
    return new Promise(function (resolve, reject) {
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new self.DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : xhr.responseText;
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new self.DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value, name) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    self.fetch = fetch;
    self.Headers = Headers;
    self.Request = Request;
    self.Response = Response;
  }
})();

(function (exports) {
  var __AVO_DEV__ = false;
  var __AVO_NOOP__ = false;
  var __AVO_LOGGER__ = null;
  var __AVO_ENV__ = null;
  var __REPORT_FAILURE_AS__ = null;
  var __STRICT__ = null;

  var __WEB_DEBUGGER__ = true;
  const avoInspectorApiKey = "5vuiZFutMlXyEw9yZfmr";
  var __INSPECTOR__ = null;

  // polyfill Array.isArray
  if (!Array.isArray) {
    Array.isArray = function (arg) {
      return Object.prototype.toString.call(arg) === '[object Array]';
    };
  }

  // polyfill Object.assign
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }

  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
  if (!Object.keys) {
    Object.keys = (function () {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
        hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
        dontEnums = [
          'toString',
          'toLocaleString',
          'valueOf',
          'hasOwnProperty',
          'isPrototypeOf',
          'propertyIsEnumerable',
          'constructor'
        ],
        dontEnumsLength = dontEnums.length;

      return function (obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [], prop, i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    }());
  }

  // polyfill Array.indexOf
  if (!Array.prototype.indexOf) Array.prototype.indexOf = (function (Object, max, min) {
    "use strict";
    return function indexOf(member, fromIndex) {
      if (this === null || this === undefined) throw TypeError("Array.prototype.indexOf called on null or undefined");

      var that = Object(this), Len = that.length >>> 0, i = min(fromIndex | 0, Len);
      if (i < 0) i = max(0, Len + i); else if (i >= Len) return -1;

      if (member === void 0) {
        for (; i !== Len; ++i) if (that[i] === void 0 && i in that) return i; // undefined
      } else if (member !== member) {
        for (; i !== Len; ++i) if (that[i] !== that[i]) return i; // NaN
      } else for (; i !== Len; ++i) if (that[i] === member) return i; // all else

      return -1; // if the value was not found, then return -1
    };
  })(Object, Math.max, Math.min);

  var array_difference, AvoAssert, InternalAvoLogger;
  array_difference = function array_difference(a1, a2) {
    var result = [];
    for (var i = 0; i < a1.length; i++) {
      if (a2.indexOf(a1[i]) === -1) {
        result.push(a1[i]);
      }
    }
    return result;
  }

  AvoAssert = {
    assertObject: function assertObject(propertyId, propName, obj) {
      if (typeof obj !== 'object') {
        var message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
        return [{ tag: 'expectedObjectType', propertyId: propertyId, message: message, actualType: typeof obj }];
      } else {
        return [];
      }
    },

    assertString: function assertString(propertyId, propName, str) {
      if (typeof str !== 'string') {
        var message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
        return [{ tag: 'expectedStringType', propertyId: propertyId, message: message, actualType: typeof str }];
      } else {
        return [];
      }
    },

    assertInt: function assertInt(propertyId, propName, int) {
      if (typeof int === 'number' && int !== Math.round(int)) {
        var message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
        return [{ tag: 'expectedIntType', propertyId: propertyId, actualType: 'float' }];
      } else if (typeof int !== 'number') {
        var message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
        return [{ tag: 'expectedIntType', propertyId: propertyId, message: message, actualType: typeof int }];
      } else {
        return [];
      }
    },

    assertLong: function assertLong(propertyId, propName, long) {
      if (typeof long === 'number' && long !== Math.round(long)) {
        var message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
        return [{ tag: 'expectedLongType', propertyId: propertyId, actualType: 'float' }];
      } else if (typeof long !== 'number') {
        var message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
        return [{ tag: 'expectedLongType', propertyId: propertyId, message: message, actualType: typeof long }];
      } else {
        return [];
      }
    },

    assertFloat: function assertFloat(propertyId, propName, float) {
      if (typeof float !== 'number') {
        var message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
        return [{ tag: 'expectedFloatType', propertyId: propertyId, message: message, actualType: typeof float }];
      } else {
        return [];
      }
    },

    assertBool: function assertBool(propertyId, propName, bool) {
      if (typeof bool !== 'boolean') {
        var message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
        return [{ tag: 'expectedBoolType', propertyId: propertyId, message: message, actualType: typeof bool }];
      } else {
        return [];
      }
    },

    assertMax: function assertMax(propertyId, propName, max, value) {
      if (value > max) {
        var message = propName +
          ' has a maximum value of ' +
          max +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{ tag: 'expectedMax', propertyId: propertyId, message: message }];
      } else {
        return [];
      }
    },

    assertMin: function assertMin(propertyId, propName, min, value) {
      if (value < min) {
        var message = propName +
          ' has a minimum value of ' +
          min +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{ tag: 'expectedMin', propertyId: propertyId, message: message }];
      } else {
        return [];
      }
    },

    assertList: function assertList(propertyId, propName, value) {
      if (!Array.isArray(value)) {
        var message = propName + ' should be of type list but you provided type ' + typeof value;
        return [{ tag: 'expectedList', propertyId: propertyId, message: message, actualType: typeof value }];
      } else {
        return [];
      }
    },

    assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{ tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message }];
      } else {
        return [];
      }
    },

    assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{ tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message }];
      } else {
        return [];
      }
    }
  };

  const WebDebuggerAssertions = {
    hasKey(obj, key) {
      return obj && obj[key] != null;
    },
    assertKey(obj, key, assert) {
      if (this.hasKey(obj, key) && assert(obj[key])) {
        return true;
      } else {
        throw new Error('WebDebugger: Object key: "' + key + '" failed assertion.')
      }
    },
    assertKeys(obj, arrayOfAssertions) {
      return arrayOfAssertions.every(assertionForKey => {
        if (this.hasKey(assertionForKey, "key") && this.hasKey(assertionForKey, "assert")) {
          return this.assertKey(obj, assertionForKey.key, assertionForKey.assert);
        } else {
          throw new Error('WebDebugger: Object form for assertion invalid.');
        }
      });
    },
    assertInt(int) {
      return Number.isInteger(int);
    },
  };

  var WebDebuggerPosition = {
    position: {
      BOTTOM_RIGHT: 'BottomRight',
      BOTTOM_LEFT: 'BottomLeft',
      TOP_RIGHT: 'TopRight',
      TOP_LEFT: 'TopLeft',
    },
    BottomRight(parameters) {
      return WebDebuggerAssertions.assertKeys(
        parameters,
        [
          { key: "bottom", assert: WebDebuggerAssertions.assertInt },
          { key: "right", assert: WebDebuggerAssertions.assertInt },
        ],
      ) && {
        position: this.position.BOTTOM_RIGHT,
        bottom: parameters.bottom,
        right: parameters.right,
      };
    },
    BottomLeft(parameters) {
      return WebDebuggerAssertions.assertKeys(
        parameters,
        [
          { key: "bottom", assert: WebDebuggerAssertions.assertInt },
          { key: "left", assert: WebDebuggerAssertions.assertInt },
        ],
      ) && {
        position: this.position.BOTTOM_LEFT,
        bottom: parameters.bottom,
        left: parameters.left,
      };
    },
    TopRight(parameters) {
      return WebDebuggerAssertions.assertKeys(
        parameters,
        [
          { key: "top", assert: WebDebuggerAssertions.assertInt },
          { key: "right", assert: WebDebuggerAssertions.assertInt },
        ],
      ) && {
        position: this.position.TOP_RIGHT,
        top: parameters.top,
        right: parameters.right,
      };
    },
    TopLeft(parameters) {
      return WebDebuggerAssertions.assertKeys(
        parameters,
        [
          { key: "top", assert: WebDebuggerAssertions.assertInt },
          { key: "left", assert: WebDebuggerAssertions.assertInt },
        ],
      ) && {
        position: this.position.TOP_LEFT,
        top: parameters.top,
        left: parameters.left,
      };
    },
  }


  InternalAvoLogger = {
    logEventSent: function logEventSent(eventName, eventProperties, userProperties, groupProperties) {
      const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties) + "Group Props:" + JSON.stringify(groupProperties);

      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties, "Group Props:", groupProperties);
    },

    log: function log(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] " + message);
    },

    warn: function warn(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.warn("[avo] " + message);
    },

    error: function error(message, error) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
        return
      }
      typeof console !== 'undefined' && console.error("[avo] " + message, error);
    }
  };

  var _avo_invoke, _avo_invoke_meta;
  var _avo_sampling_rate = 1.0;
  function _avo_invoke_payload(body) {
    if (typeof window === 'undefined') { return; }
    if (_avo_sampling_rate > 0) {
      if (Math.random() < _avo_sampling_rate) {
        fetch("https://api.avo.app/i", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body)
        }).then(function (res) { return res.json(); }).then(function (data) { _avo_sampling_rate = data.sa; }).catch(function () { });
      }
    }
  }

  _avo_invoke = function _avo_invoke(env, eventId, hash, messages, origin) {
    _avo_invoke_payload({
      "ac": "Wnrn6ZqiWlDqdKqytcKP",
      "br": "master",
      "en": env,
      "ev": eventId,
      "ha": hash,
      "sc": "5VWPHSaRaWcJAxzqYkj4",
      "se": (new Date()).toISOString(),
      "so": "eOheVbWjQ",
      "va": messages.length === 0,
      "me": messages,
      "or": origin
    });
  }

  _avo_invoke_meta = function _avo_invoke_meta(env, type, messages, origin) {
    _avo_invoke_payload({
      "ac": "Wnrn6ZqiWlDqdKqytcKP",
      "br": "master",
      "en": env,
      "ty": type,
      "sc": "5VWPHSaRaWcJAxzqYkj4",
      "se": (new Date()).toISOString(),
      "so": "eOheVbWjQ",
      "va": messages.length === 0,
      "me": messages,
      "or": origin
    });
  }


  var _avo_debugger_log;
  var _avo_debugger_events_during_boot = [];
  var _avo_debugger_ready = false;

  if (typeof window !== 'undefined') {
    window.addEventListener("message", function (event) {
      if (event.origin !== "https://www.avo.app") {
        return;
      }
      var iframe = document.getElementById("avo-debugger");
      if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
        iframe.style = event.data.style;
      } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
        var message = {
          type_: "avo-debugger-boot-events",
          schemaId: "5VWPHSaRaWcJAxzqYkj4",
          href: window.location.href,
          events: _avo_debugger_events_during_boot
        };
        _avo_debugger_events_during_boot = [];
        _avo_debugger_ready = true;
        iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
      }
    });
  }

  _avo_debugger_log = function _avo_debugger_log(eventId, eventName, messages, eventProperties, userProperties, groupProperties) {
    if (typeof window === 'undefined') { return; }
    var event = {
      eventId: eventId,
      eventName: eventName,
      messages: messages,
      timestamp: Date.now(),
      eventProperties: eventProperties,
      userProperties: userProperties,
      groupProperties: groupProperties
    };

    if (_avo_debugger_ready) {
      var message = { type_: "avo-debugger-events", events: [event] };
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    } else {
      _avo_debugger_events_during_boot.push(event);
    }
  }

  function _avo_debugger_send_position(position) {
    if (typeof window === 'undefined') { return; }
    window.addEventListener("message", function (event) {
      if (event.origin !== "https://www.avo.app") {
        return;
      }
      if (event.data.type_ === "avo-debugger-ready" && position !== null) {
        var message = { type_: "avo-debugger-position", position: position };
        document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
      }
    })
  }


  var DeviceType = {
    MOBILE: "mobile",
    TABLET: "tablet",
    DESKTOP: "desktop",
    NATIVE_MOBILE: "native_mobile",
  };

  var ElementStyle = {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    TERTIARY: "tertiary",
    WARNING: "warning",
    MEDIUM: "medium",
    SMALL: "small",
    ICON: "icon",
  };

  var PageCategory = {
    DASHBOARD: "dashboard",
    WORDPRESS_MINUSPAGE: "wordpress-page",
  };

  var ButtonFunction = {
    PLAY: "play",
    OPEN_MINUSMENU: "open-menu",
  };

  function assertDeviceType(deviceType, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("17xm7taAE4", label_ ? "device_type" + ": " + label_ : "device_type", deviceType));
    if ("mobile" !== deviceType && "tablet" !== deviceType &&
      "desktop" !== deviceType && "native_mobile" !== deviceType) {
      var message = (label_ ? "device_type" + ": " + label_ : "device_type") + " should match one of the following values [ mobile | tablet | desktop | native_mobile ] but you provided the value " + deviceType
      messages = messages.concat([{ tag: 'expectedStringMatch', propertyId: "17xm7taAE4", message: message }]);
    }
    return messages;
  }

  function assertOptionalScreenDetails(screenDetails, label_) {
    var messages = [];
    if (screenDetails !== undefined && screenDetails !== null) {
      messages = messages.concat(AvoAssert.assertString("3gyTDWP8y", label_ ? "screen_details" + ": " + label_ : "screen_details", screenDetails));
    }
    return messages;
  }

  function assertOptionalPageSlug(pageSlug, label_) {
    var messages = [];
    if (pageSlug !== undefined && pageSlug !== null) {
      messages = messages.concat(AvoAssert.assertString("4zk9SfX5m", label_ ? "page_slug" + ": " + label_ : "page_slug", pageSlug));
    }
    return messages;
  }

  function assertOptionalScreenName(screenName, label_) {
    var messages = [];
    if (screenName !== undefined && screenName !== null) {
      messages = messages.concat(AvoAssert.assertString("7-hb0S6NBb", label_ ? "screen_name" + ": " + label_ : "screen_name", screenName));
    }
    return messages;
  }

  function assertOptionalElementAsset(elementAsset, label_) {
    var messages = [];
    if (elementAsset !== undefined && elementAsset !== null) {
      messages = messages.concat(AvoAssert.assertString("80_ryv9tLt", label_ ? "element_asset" + ": " + label_ : "element_asset", elementAsset));
    }
    return messages;
  }

  function assertOptionalExperimentFlagKey(experimentFlagKey, label_) {
    var messages = [];
    if (experimentFlagKey !== undefined && experimentFlagKey !== null) {
      messages = messages.concat(AvoAssert.assertString("99aAmAzEL", label_ ? "experiment_flag_key" + ": " + label_ : "experiment_flag_key", experimentFlagKey));
    }
    return messages;
  }

  function assertOptionalElementValue(elementValue, label_) {
    var messages = [];
    if (elementValue !== undefined && elementValue !== null) {
      messages = messages.concat(AvoAssert.assertString("GTORnQtlw", label_ ? "element_value" + ": " + label_ : "element_value", elementValue));
    }
    return messages;
  }

  function assertOptionalVisitUuid(visitUuid, label_) {
    var messages = [];
    if (visitUuid !== undefined && visitUuid !== null) {
      messages = messages.concat(AvoAssert.assertString("URqdDQwOo5", label_ ? "visit_uuid" + ": " + label_ : "visit_uuid", visitUuid));
    }
    return messages;
  }

  function assertOptionalElementText(elementText, label_) {
    var messages = [];
    if (elementText !== undefined && elementText !== null) {
      messages = messages.concat(AvoAssert.assertString("Uo6pFY11e3", label_ ? "element_text" + ": " + label_ : "element_text", elementText));
    }
    return messages;
  }

  function assertOptionalElementStyle(elementStyle, label_) {
    var messages = [];
    if (elementStyle !== undefined && elementStyle !== null) {
      messages = messages.concat(AvoAssert.assertString("Xw0pqBJrD", label_ ? "element_style" + ": " + label_ : "element_style", elementStyle));
      if ("primary" !== elementStyle && "secondary" !== elementStyle &&
        "tertiary" !== elementStyle && "warning" !== elementStyle &&
        "medium" !== elementStyle && "small" !== elementStyle &&
        "icon" !== elementStyle) {
        var message = (label_ ? "element_style" + ": " + label_ : "element_style") + " should match one of the following values [ primary | secondary | tertiary | warning | medium | small | icon ] but you provided the value " + elementStyle
        messages = messages.concat([{ tag: 'expectedStringMatch', propertyId: "Xw0pqBJrD", message: message }]);
      }
    }
    return messages;
  }

  function assertOptionalExperimentSlug(experimentSlug, label_) {
    var messages = [];
    if (experimentSlug !== undefined && experimentSlug !== null) {
      messages = messages.concat(AvoAssert.assertString("XxDnqKyC0", label_ ? "experiment_slug" + ": " + label_ : "experiment_slug", experimentSlug));
    }
    return messages;
  }

  function assertPageCategory(pageCategory, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("l8x9M6i2L", label_ ? "page_category" + ": " + label_ : "page_category", pageCategory));
    if ("dashboard" !== pageCategory && "wordpress-page" !== pageCategory) {
      var message = (label_ ? "page_category" + ": " + label_ : "page_category") + " should match one of the following values [ dashboard | wordpress-page ] but you provided the value " + pageCategory
      messages = messages.concat([{ tag: 'expectedStringMatch', propertyId: "l8x9M6i2L", message: message }]);
    }
    return messages;
  }

  function assertOptionalButtonFunction(buttonFunction, label_) {
    var messages = [];
    if (buttonFunction !== undefined && buttonFunction !== null) {
      messages = messages.concat(AvoAssert.assertString("o6WPDaADo", label_ ? "button_function" + ": " + label_ : "button_function", buttonFunction));
      if ("play" !== buttonFunction && "open-menu" !== buttonFunction) {
        var message = (label_ ? "button_function" + ": " + label_ : "button_function") + " should match one of the following values [ play | open-menu ] but you provided the value " + buttonFunction
        messages = messages.concat([{ tag: 'expectedStringMatch', propertyId: "o6WPDaADo", message: message }]);
      }
    }
    return messages;
  }

  function assertElementName(elementName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("rfMghFZJ2R", label_ ? "element_name" + ": " + label_ : "element_name", elementName));
    return messages;
  }

  function assertOptionalElementPosition(elementPosition, label_) {
    var messages = [];
    if (elementPosition !== undefined && elementPosition !== null) {
      messages = messages.concat(AvoAssert.assertString("tPlGV1TSw", label_ ? "element_position" + ": " + label_ : "element_position", elementPosition));
    }
    return messages;
  }

  function assertOptionalExperimentVariation(experimentVariation, label_) {
    var messages = [];
    if (experimentVariation !== undefined && experimentVariation !== null) {
      messages = messages.concat(AvoAssert.assertString("uqmNDBGLL", label_ ? "experiment_variation" + ": " + label_ : "experiment_variation", experimentVariation));
    }
    return messages;
  }

  function setAvoLogger(avoLogger) {
    __AVO_LOGGER__ = avoLogger;
  }

  var sysDeviceType;
  var sysVisitUuid;

  function setSystemProperties(properties) {
    if (properties.deviceType !== null && properties.deviceType !== undefined) {
      sysDeviceType = properties.deviceType;
      assertDeviceType(sysDeviceType);
    }
    if (properties.visitUuid !== null && properties.visitUuid !== undefined) {
      sysVisitUuid = properties.visitUuid;
      assertOptionalVisitUuid(sysVisitUuid);
    }
  }

  function setSystemProperties_(properties) {
    if (properties.deviceType !== null && properties.deviceType !== undefined) {
      sysDeviceType = properties.deviceType;
      assertDeviceType(sysDeviceType);
    }
    if (properties.visitUuid !== null && properties.visitUuid !== undefined) {
      sysVisitUuid = properties.visitUuid;
      assertOptionalVisitUuid(sysVisitUuid);
    }
  }


  var RudderStack;
  function setup_(options, systemProperties, RudderStackDestination,
    destinationOptions) {
    __WEB_DEBUGGER__ = false;
    if (options.validateProperties === true) {
      __AVO_DEV__ = true;
    }

    setSystemProperties_(systemProperties);

    destinationOptions = destinationOptions || {};

    if (!__AVO_NOOP__) {
      if (options.useProductionKey) {
      } else {
      }

      RudderStack = RudderStackDestination;
      RudderStack.make(options.useProductionKey);
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'setup', [], 'setup');
      }
    }
  }

  function initAvo(options, systemProperties, destinationOptions,
    RudderStackDestination) {
    if (__AVO_ENV__ !== null) {
      return;
    }
    __AVO_ENV__ = options.env.toLowerCase();
    if (options.avoLogger !== undefined) {
      __AVO_LOGGER__ = options.avoLogger;
    }
    if (options.noop === true) {
      __AVO_NOOP__ = true;
    }
    if (__AVO_NOOP__ && __AVO_ENV__ == 'prod') {
      InternalAvoLogger.warn("****************************************************");
      InternalAvoLogger.warn("WARNING Avo cannot be initialized in noop mode in production:");
      InternalAvoLogger.warn("- Overwriting configuration with noop=false.");
      InternalAvoLogger.warn("- Please reach out if you want to be able to run Avo in production mode with noop=true");
      InternalAvoLogger.warn("****************************************************");
      __AVO_NOOP__ = false;
    }
    if (__AVO_NOOP__) {
      InternalAvoLogger.log("****************************************************");
      InternalAvoLogger.log("Avo is now initialized in noop mode. This means:");
      InternalAvoLogger.log("- No events will be sent");
      InternalAvoLogger.log("- No network requests are made");
      InternalAvoLogger.log("****************************************************");
    }
    if (options.strict !== undefined) {
      __STRICT__ = options.strict !== false;
    }
    if (options.reportFailureAs !== undefined) {
      __REPORT_FAILURE_AS__ = options.reportFailureAs;
    }
    __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && window.location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== 'prod'));
    if (!__AVO_NOOP__ && options.inspector !== undefined) {
      __INSPECTOR__ = options.inspector;
    } else if (__AVO_ENV__ !== 'prod') {
      InternalAvoLogger.warn("Avo Inspector not provided in initAvo() call");
    }
    if (__AVO_ENV__ !== 'prod') {
      __AVO_DEV__ = true;
    }

    setSystemProperties(systemProperties);

    destinationOptions = destinationOptions || {};

    if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
      if (options.webDebuggerOptions && options.webDebuggerOptions.position) {
        const {
          BOTTOM_RIGHT,
          BOTTOM_LEFT,
          TOP_RIGHT,
          TOP_LEFT,
        } =
          WebDebuggerPosition.position;

        try {
          WebDebuggerAssertions.assertKey(
            options.webDebuggerOptions.position,
            "position",
            position => {
              return position === BOTTOM_RIGHT ||
                position === BOTTOM_LEFT ||
                position === TOP_RIGHT ||
                position === TOP_LEFT;
            }
          )
        } catch (e) {
          throw new Error('WebDebugger: Position object incorrect. Use methods from WebDebuggerPosition.');
        }

        const top = options.webDebuggerOptions.position.top || 0;
        const right = options.webDebuggerOptions.position.right || 0;
        const bottom = options.webDebuggerOptions.position.bottom || 0;
        const left = options.webDebuggerOptions.position.left || 0;

        let positionParams;

        switch (options.webDebuggerOptions.position.position) {
          case BOTTOM_RIGHT:
            positionParams = {
              position: BOTTOM_RIGHT,
              bottom,
              right,
            };
            break;
          case BOTTOM_LEFT:
            positionParams = {
              position: BOTTOM_LEFT,
              bottom,
              left,
            };
            break;
          case TOP_RIGHT:
            positionParams = {
              position: TOP_RIGHT,
              top,
              right,
            };
            break;
          case TOP_LEFT:
            positionParams = {
              position: TOP_LEFT,
              top,
              left,
            };
            break;
          default: throw new Error('WebDebugger: Position object incorrect. Use methods from WebDebuggerPosition.');
        }

        _avo_debugger_send_position(positionParams);
      }

      (function () {
        if (typeof window === 'undefined') { return; }
        var init = function () {
          if (document.getElementById("avo-debugger") === null) {
            var iframe = document.createElement("iframe");
            document.body.appendChild(iframe);
            iframe.id = "avo-debugger";
            iframe.src = "https://www.avo.app/_debugger";
            iframe.style = "display: none;";
          }
        };

        if (document.body) {
          init();
        } else {
          document.addEventListener('DOMContentLoaded', init);
        }
      })();

    }
    if (!__AVO_NOOP__) {
      if (__AVO_ENV__ === 'prod') {
      }
      if (__AVO_ENV__ === 'dev') {
      }

      RudderStack = RudderStackDestination;
      if (__AVO_ENV__ === 'prod') {
        RudderStack.make && RudderStack.make(__AVO_ENV__, null);
      } else if (__AVO_ENV__ === 'dev') {
        RudderStack.make && RudderStack.make(__AVO_ENV__, null);
      } else {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for RudderStack. Head to destination settings in Avo to set a staging key.");
        RudderStack.make && RudderStack.make(__AVO_ENV__, null);
      }
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
      }
    }
  }

  /**
   * button_pressed: Web: every time an interactive element is clicked (and the URL does not change). Example: like button, modal button, sort toggle, accordion toggle, etc.

Mobile: every time an interactive element is tapped (that is not a link to a webview).
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} [properties.elementText] - The readable text of the element
   * @param {string} properties.elementName - A semi-unique identifier of the element.
   * @param {string} [properties.elementAsset] - The name of the asset, if the element is not readable (in the case of an image-only element).
   * @param {string} [properties.elementStyle] - The style of the element, as specified by our design library.
   * @param {string} [properties.elementPosition] - Position of the element on the page.
   * @param {string} [properties.elementValue] - The value associated with an element. For cases where additional context is needed.
   * @param {string} [properties.screenName] - The name of the screen that triggers the event.
   * @param {string} [properties.screenDetails] - DEPRECATED: DO NOT USE

Any extra screen details, e.g. [storyname}_branch_1 [optional]
   * @param {string} [properties.experimentVariation] - The variation for the Launch Darkly experiment that the user is currently seeing.
   * @param {string} [properties.experimentFlagKey] - The Launch Darkly feature flag key.
   * @param {string} [properties.experimentSlug] - The Launch Darkly experiment slug that identifies the experiment.
   * @param {string} [properties.pageSlug] - The slug of the page, typically from a Wordpress hook.
   * @param {string} properties.pageCategory - Category of page, in order to group certain pages together.
   * @param {string} [properties.buttonFunction] - The function of the button. Generally only provided if the button does something special.
   *
   * @see {@link https://www.avo.app/schemas/5VWPHSaRaWcJAxzqYkj4/events/VOzldlf3Ji}
   */
  function buttonPressed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertOptionalElementText(properties.elementText));
      messages = messages.concat(assertElementName(properties.elementName));
      messages = messages.concat(assertOptionalElementAsset(properties.elementAsset));
      messages = messages.concat(assertOptionalElementStyle(properties.elementStyle));
      messages = messages.concat(assertOptionalElementPosition(properties.elementPosition));
      messages = messages.concat(assertOptionalElementValue(properties.elementValue));
      messages = messages.concat(assertOptionalScreenName(properties.screenName));
      messages = messages.concat(assertOptionalScreenDetails(properties.screenDetails));
      messages = messages.concat(assertOptionalExperimentVariation(properties.experimentVariation));
      messages = messages.concat(assertOptionalExperimentFlagKey(properties.experimentFlagKey));
      messages = messages.concat(assertOptionalExperimentSlug(properties.experimentSlug));
      messages = messages.concat(assertOptionalPageSlug(properties.pageSlug));
      messages = messages.concat(assertPageCategory(properties.pageCategory));
      messages = messages.concat(assertOptionalButtonFunction(properties.buttonFunction));
      messages = messages.concat(assertDeviceType(sysDeviceType));
      messages = messages.concat(assertOptionalVisitUuid(sysVisitUuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("button_pressed", Object.keys(properties), [
        "elementText",
        "elementName",
        "elementAsset",
        "elementStyle",
        "elementPosition",
        "elementValue",
        "screenName",
        "screenDetails",
        "experimentVariation",
        "experimentFlagKey",
        "experimentSlug",
        "pageSlug",
        "pageCategory",
        "buttonFunction"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "VOzldlf3Ji", "20c5f93a196097554fb5ba248595aa54eadb0b24fb0fadf16dea96e83dc12868", messages.map(function (m) { return Object.assign({}, { tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType }); }), 'event');
      }

      InternalAvoLogger.logEventSent("button_pressed", {
        "element_text": properties.elementText,
        "element_name": properties.elementName,
        "element_asset": properties.elementAsset,
        "element_style": properties.elementStyle,
        "element_position": properties.elementPosition,
        "element_value": properties.elementValue,
        "screen_name": properties.screenName,
        "screen_details": properties.screenDetails,
        "experiment_variation": properties.experimentVariation,
        "experiment_flag_key": properties.experimentFlagKey,
        "experiment_slug": properties.experimentSlug,
        "page_slug": properties.pageSlug,
        "page_category": properties.pageCategory,
        "button_function": properties.buttonFunction,
        "device_type": sysDeviceType,
        "visit_uuid": sysVisitUuid,
      }, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("VOzldlf3Ji", "button_pressed", messages, [
          { id: "3gyTDWP8y", name: "screen_details", value: properties.screenDetails },
          { id: "4zk9SfX5m", name: "page_slug", value: properties.pageSlug },
          { id: "7-hb0S6NBb", name: "screen_name", value: properties.screenName },
          { id: "80_ryv9tLt", name: "element_asset", value: properties.elementAsset },
          { id: "99aAmAzEL", name: "experiment_flag_key", value: properties.experimentFlagKey },
          { id: "GTORnQtlw", name: "element_value", value: properties.elementValue },
          { id: "Uo6pFY11e3", name: "element_text", value: properties.elementText },
          { id: "Xw0pqBJrD", name: "element_style", value: properties.elementStyle },
          { id: "XxDnqKyC0", name: "experiment_slug", value: properties.experimentSlug },
          { id: "l8x9M6i2L", name: "page_category", value: properties.pageCategory },
          { id: "o6WPDaADo", name: "button_function", value: properties.buttonFunction },
          { id: "rfMghFZJ2R", name: "element_name", value: properties.elementName },
          { id: "tPlGV1TSw", name: "element_position", value: properties.elementPosition },
          { id: "uqmNDBGLL", name: "experiment_variation", value: properties.experimentVariation },
          { id: "17xm7taAE4", name: "device_type", value: sysDeviceType },
          { id: "URqdDQwOo5", name: "visit_uuid", value: sysVisitUuid },
        ], [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'button_pressed': " + messages[0].message)
        }
      } else {
        messages.forEach(function (m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("button_pressed", {
          "element_text": properties.elementText,
          "element_name": properties.elementName,
          "element_asset": properties.elementAsset,
          "element_style": properties.elementStyle,
          "element_position": properties.elementPosition,
          "element_value": properties.elementValue,
          "screen_name": properties.screenName,
          "screen_details": properties.screenDetails,
          "experiment_variation": properties.experimentVariation,
          "experiment_flag_key": properties.experimentFlagKey,
          "experiment_slug": properties.experimentSlug,
          "page_slug": properties.pageSlug,
          "page_category": properties.pageCategory,
          "button_function": properties.buttonFunction,
          "device_type": sysDeviceType,
          "visit_uuid": sysVisitUuid,
        }, "VOzldlf3Ji", "20c5f93a196097554fb5ba248595aa54eadb0b24fb0fadf16dea96e83dc12868");
      }
      // destination RudderStack
      RudderStack.logEvent("button_pressed", {
        "element_text": properties.elementText,
        "element_name": properties.elementName,
        "element_asset": properties.elementAsset,
        "element_style": properties.elementStyle,
        "element_position": properties.elementPosition,
        "element_value": properties.elementValue,
        "screen_name": properties.screenName,
        "screen_details": properties.screenDetails,
        "experiment_variation": properties.experimentVariation,
        "experiment_flag_key": properties.experimentFlagKey,
        "experiment_slug": properties.experimentSlug,
        "page_slug": properties.pageSlug,
        "page_category": properties.pageCategory,
        "button_function": properties.buttonFunction,
        "device_type": sysDeviceType,
        "visit_uuid": sysVisitUuid,
      });
    } else {
      // do nothing
    }
  }

  exports.DeviceType = DeviceType;
  exports.ElementStyle = ElementStyle;
  exports.PageCategory = PageCategory;
  exports.ButtonFunction = ButtonFunction;
  exports.setAvoLogger = setAvoLogger;
  exports.buttonPressed = buttonPressed;
  exports.setSystemProperties_ = setSystemProperties_;
  exports.setSystemProperties = setSystemProperties;
  exports.setup_ = setup_;
  exports.initAvo = initAvo;
  exports.WebDebuggerPosition = WebDebuggerPosition;
  exports.avoInspectorApiKey = avoInspectorApiKey;
}(typeof exports === 'undefined' ? this.Avo = {} : exports));

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["buttonPressed"]
