import { useEffect } from 'react';
import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import { Navigate, useSearchParams } from 'react-router-dom';
import useEnv from '@zenbusiness/application-commons-env';

const Login = () => {
  const { DEFAULT_ROUTE } = useEnv('root-first');
  const { login, authenticated } = useAuthentication();
  const [{ redirectTo }] = useSearchParams();

  useEffect(() => {
    if (!authenticated) login(redirectTo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authenticated) {
    return (<Navigate to={DEFAULT_ROUTE} />);
  }

  return null;
};

export default Login;
