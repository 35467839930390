import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import useEnv from '@zenbusiness/application-commons-env';
import Loading from '#client/components/Loading';

const Logout = () => {
  const { DEFAULT_ROUTE } = useEnv('root-first');
  const { logout } = useAuthentication();
  const [{ redirectTo }] = useSearchParams();

  useEffect(() => {
    logout(redirectTo || DEFAULT_ROUTE);
  });

  return (
    <>
      <Loading />
      <Box sx={{ margin: '50px auto' }}>
        <Typography variant="h3" gutterBottom align="center">
          Logging out of your ZenBusiness account...
        </Typography>
      </Box>
    </>
  );
};

export default Logout;
