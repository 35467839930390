import { useEffect } from 'react';

import PropTypes from 'prop-types';

const RedirectExternal = ({ href, replace = false }) => {
  useEffect(() => {
    if (href) {
      if (replace) {
        window.location.replace(href);
      } else {
        window.location.href = href;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

RedirectExternal.propTypes = {
  href: PropTypes.string.isRequired,
  replace: PropTypes.bool
};

export default RedirectExternal;
