import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import useEnv from '@zenbusiness/application-commons-env';
import Loading from '#client/components/Loading';
import { AUTH_PATHS } from '#client/build-env';
import RedirectExternal from '#client/components/RedirectExternal';

/* Taken from
 * https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
 */
const AUTH0_ERROR_CODES = new Set([
  /* expected error when d/callback is hit without login */
  'empty_login_state',
  'access_denied',
  'invalid_user_password',
  'mfa_invalid_code',
  'mfa_registration_required',
  'mfa_required',
  'password_leaked',
  'PasswordHistoryError',
  'PasswordStrengthError',
  'too_many_attempts',
  'unauthorized',
  'timeout'
]);

const Callback = () => {
  const { DEFAULT_ROUTE } = useEnv();
  const {
    authenticated,
    callback
  } = useAuthentication();
  const [loading, setLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState(DEFAULT_ROUTE);
  const [exception, setException] = useState(null);

  useEffect(() => {
    if (!loading) return;
    if (authenticated) {
      setLoading(false);
      return;
    }

    callback().then(
      (auth) => {
        const { redirectTo: url } = auth;
        if (url && url.startsWith('/')) {
          setRedirectTo(url);
        }
        setLoading(false);
      }
    ).catch(setException);
  }, [authenticated, loading, callback]);

  if (exception) {
    if (AUTH0_ERROR_CODES.has(exception.code)) {
      return (
        <RedirectExternal
          href={`${AUTH_PATHS.loginUri}?ecode=${exception.error || 'generic'}`}
          replace
        />
      );
    }

    throw exception;
  }

  if (loading || !authenticated) {
    // Wait until callback processed, then redirect to base page.
    return (
      <Loading />
    );
  }

  return (
    <Navigate to={redirectTo} />
  );
};

export default Callback;
