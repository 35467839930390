import { Suspense } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useEnv } from '@zenbusiness/application-commons-env';

import { LoggingProvider, ErrorBoundary } from '@zenbusiness/application-commons-logging';

import { AuthenticationProvider } from '@zenbusiness/application-commons-authentication';
import { ClickstreamProvider } from '@zenbusiness/application-commons-clickstream';
import { GraphQLProvider } from '@zenbusiness/application-commons-graphql';
import { IdentityProvider } from '@zenbusiness/application-commons-identity';
import { lightTheme } from '@zenbusiness/common-components/theme';
import ErrorPage from '@zenbusiness/dashboard-components/ErrorPage';

import { NAME } from '#client/build-env';
import Loading from '#client/components/Loading';
import { gqlErrorHandler } from '#client/lib/errorHandler';
import avo from '#client/avo';

const emotionCache = createCache({
  key: NAME
});

// eslint-disable-next-line react/prop-types
const App = ({ children }) => {
  const {
    AUTH0_AUDIENCE,
    AUTH0_CLIENTID,
    AUTH0_CONNECTION,
    AUTH0_DOMAIN,
    DEPLOY_ENV,
    GRAPHQL_ROUTE,
    RUDDERSTACK_API_KEY,
    RUDDERSTACK_API_URL,
    AUTH_PATHS
  } = useEnv('head-first'); // use MFE values
  const {
    SENTRY_PROFILING,
    SENTRY_TRACING,
    SENTRY_SAMPLE_RATE
  } = useEnv('root-first'); // use parent values

  return (
    <LoggingProvider
      name={NAME}
      profiling={SENTRY_PROFILING}
      tracing={SENTRY_TRACING}
      sampleRate={SENTRY_SAMPLE_RATE}
    >
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <AuthenticationProvider
            clientID={AUTH0_CLIENTID}
            domain={AUTH0_DOMAIN}
            audience={AUTH0_AUDIENCE}
            connection={AUTH0_CONNECTION}
            {...AUTH_PATHS}
          >
            <ClickstreamProvider
              avo={avo}
              deployEnv={DEPLOY_ENV}
              rudderstackApiKey={RUDDERSTACK_API_KEY}
              rudderstackApiUrl={RUDDERSTACK_API_URL}
            >
              <GraphQLProvider url={GRAPHQL_ROUTE} onError={gqlErrorHandler}>
                <IdentityProvider>
                  <ErrorBoundary fallback={<ErrorPage />}>
                    <Suspense fallback={<Loading />}>
                      {children}
                    </Suspense>
                  </ErrorBoundary>
                </IdentityProvider>
              </GraphQLProvider>
            </ClickstreamProvider>
          </AuthenticationProvider>
        </ThemeProvider>
      </CacheProvider>
    </LoggingProvider>
  );
};

export default App;
