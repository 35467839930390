import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import SharedAsset from '@zenbusiness/common-components/SharedAsset';
import Title from '@zenbusiness/common-components/Title';
import { useIdentity } from '@zenbusiness/application-commons-identity';

const FlexRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
});

const Landing = () => {
  const navigate = useNavigate();
  const { authenticated, identified, firstName } = useIdentity();
  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="20vh" m={5}>
        <Title>{identified ? `Welcome ${firstName}!` : 'Welcome to ZenGarden!'}</Title>
        <SharedAsset src="/illustration/dog-celebrate-right-256.svg" />
      </Box>
      {!authenticated && (
      <FlexRow justifyContent="center" alignItems="center" m={1}>
        <Button variant="outlined" onClick={() => (navigate('/login'))}>Login With Okta</Button>
      </FlexRow>
      )}
    </>
  );
};

export default Landing;
