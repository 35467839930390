import { css, styled } from '@mui/system';

import LinearProgress from '@mui/material/LinearProgress';

const LoadingRoot = styled('div', {
  name: 'Loading',
  slot: 'Root'
})(css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-basis: auto;

  > .MuiLinearProgress-root {
    flex: 1 1 auto;
  }
`);

const Loading = (props) => (
  <LoadingRoot {...props} data-testid="loading">
    <LinearProgress />
  </LoadingRoot>
);

export default Loading;
