import { Outlet } from 'react-router-dom';
import DevBanner from './DevBanner';
import TopNav from './TopNav';

export default function Template({ children }) {
  return (
    <>
      <DevBanner />
      <TopNav />
      {children || <Outlet />}
    </>
  );
}
